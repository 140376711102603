var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',_vm._g(_vm._b({staticClass:"Button",class:[
    ("Button--" + _vm.color),
    {
      'is-active': _vm.active,
      'is-disabled': _vm.disabled,
      'rounded-full': _vm.rounded,
      'py-1 px-3 text-xs': _vm.size === 'sm' || _vm.size === 'small',
      'py-2 px-4 text-sm': _vm.size === 'md' || _vm.size === 'medium',
      'py-3 px-5': _vm.size === 'normal',
      'is-border-thin': _vm.thinBorder,
    } ],attrs:{"id":_vm.label,"label":_vm.label,"aria-label":_vm.label},on:{"click":_vm.click}},'button',_vm.$attrs,false),_vm.$listeners),[_c('div',{staticClass:"Button-inner"},[(_vm.icon && !_vm.iconRight)?_c('div',[_c('Icon',{staticClass:"Button-icon",class:[
          _vm.iconClass,
          {
            'mr-1': _vm.size === 'sm' && !!_vm.label,
            'mr-2': _vm.size === 'md' && !!_vm.label,
            'mr-4': _vm.size === 'normal' && !!_vm.label,
          } ],attrs:{"name":_vm.icon,"set":_vm.iconset,"spin":_vm.iconSpin}})],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"Button-text"},[_vm._v("\n      "+_vm._s(_vm.text)+"\n      "),_vm._t("default")],2),_vm._v(" "),(_vm.icon && _vm.iconRight)?_c('div',[_c('Icon',{staticClass:"Button-icon",class:{
          'ml-1': _vm.size === 'sm' && !!_vm.label,
          'ml-2': _vm.size === 'md' && !!_vm.label,
          'ml-4': _vm.size === 'normal' && !!_vm.label,
        },attrs:{"name":_vm.icon,"set":_vm.iconset,"spin":_vm.iconSpin}})],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }