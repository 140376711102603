//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { assetURL, validateEmail } from '@/plugins/utilities'

export default {
  data() {
    return {
      bgPath: {
        cf_path: '/T2cGGvrxnttw33uG-email-signup-background-home/Full',
        extension: 'jpg',
        format: 'image/jpeg',
      },
      bgPathMobile: {
        cf_path: '/uCMOhiiwtLjsp12a-email-signup-mobile/Full',
        extension: 'jpg',
        format: 'image/jpeg',
      },
      subscribeEmail: '',
      subscribeError: null,
      isJustSubscribed: false,
      isMobileMenuOpen: false,
    }
  },
  methods: {
    assetURL,
    getCurrency(str) {
      const country = this.$i18n.locales.filter((locale) => {
        return locale.code === this.$i18n.locale
      })
      return new Intl.NumberFormat(this.$i18n.locale, {
        style: 'currency',
        currency: country[0].currencyCode,
        maximumSignificantDigits: 3,
      }).format(str)
    },
    async subscribe() {
      if (!validateEmail(this.subscribeEmail)) {
        this.subscribeError = this.$t('enter_valid_email')
        return
      }

      try {
        const result = await this.$axios.$post('newsletters/email', {
          email: this.subscribeEmail,
        })

        if (result) {
          this.subscribeError = null
          this.isJustSubscribed = true
          this.subscribeEmail = ''

          setTimeout(() => {
            this.isJustSubscribed = false
          }, 5000)
        }
      } catch (e) {
        this.subscribeError = 'Cannot subscribe, please try again later'
      }
    },
  },
}
