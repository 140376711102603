//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import 'lazysizes'
export default {
  props: {
    src: {
      type: [String, Object],
      required: true,
    },
    fullSize: {
      type: Boolean,
      required: false,
    },
    growOnHover: {
      type: Boolean,
      default: false,
    },
    shrinkOnHover: {
      type: Boolean,
      default: false,
    },
    loadingImg: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    getSrc() {
      let src = this.src
      if (this.src?.cf_path) {
        src = `${this.src.cf_path}/2400w`
      }
      return src
    },
  },
}
